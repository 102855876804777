/*

  390.32px - Max text width on US letter split (leaving margin on each side)

*/

html, body {
  background: #fff;
}

.menu {
  /*background-image: image-url('patterns/linen.png');*/
  background-color: #fff;
  border: 1px solid #eee;
  box-shadow: 0 0 20px rgba(0,0,0,0.5);
  padding-top: 5rem;
  margin-bottom: 4rem;
}
.printing .menu { border: none; }

.menu textarea {
  margin: 0;
  resize: none;
  border: none;
}

textarea.menu-title {
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.1;
}

textarea.menu-date {
  margin-bottom: 4rem;
}

textarea.item-name {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.1;
}

ol.menu-items {
  list-style-type: none;
  margin-left: 0;
  padding: 0;
}

.menu-item {
  margin-bottom: 4rem;
  position: relative;
}

.menu-item.editing {
  /*transform: scale(1.1);*/
  background: #eee;
  /*box-shadow: 0 10px 20px rgba(0,0,0,0.5);*/
}
.menu-item.editing textarea {
  background: #eee;
}

.menu-item p {
  margin-bottom: 0;
  min-height: 1rem;
}

.menu-item form {
  background-color: #eee;
  padding: 1rem;
}

.editing .remove-item {
  display: block;
}
.remove-item {
  font-size: 80%;
  border: none;
  border-radius: 3px;
  text-align: center;
  margin: 0;
  background: transparent;
  color: #666;
  position: absolute;
  left: 43%;
  bottom: -1.5rem;
  display: none;
  text-decoration: underline;
}
.remove-item:hover {
  color: white;
  background: red;
  text-decoration: none;
}
.add-item {
  text-align: center;
}

/* Letter 1/2 */
.menu.letter-2 {
  max-width: 390.32px;
  margin-left: auto;
  margin-right: auto;
}

/* Classic */
@import url('https://fonts.googleapis.com/css?family=EB+Garamond|Fondamento');
.classic.menu {
  text-align: center;
}
.classic.menu .font-header, .classic.menu .item-name {
  font-family: 'Fondamento', cursive;
}
.classic.menu .font-body, .classic.menu .item-description, .classic.menu .item-price {
  font-family: 'EB Garamond', serif;
}
.classic.menu textarea {
  text-align: center;
  width: 100%;
}

/* Modern */
.modern.menu {
  text-align: left;
  padding-left: 5px;
}
.modern.menu .font-header, .modern.menu .item-name {
  font-weight: bold;
}
.modern.menu .font-body, .modern.menu .item-description, .modern.menu .item-price {

}
.modern.menu textarea {
  text-align: left;
  width: 100%;
}


/* PDF Generation / Printing*/
.printing .no-print, .printing .no-print * {
  display: none !important;
}

.menu-print {
  display: none;
}
.printing .menu-print {
  display: block;
}

.menu-preview {
  display: block;
}
.printing .menu-preview {
  display: none;
}

@page {
  size: US letter;
  margin: 0; /* full-bleed */
  padding: 0;
}
